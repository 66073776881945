import { Button } from '@mui/material';
import { getFaqUrl } from 'helpers/Helpers';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';

const FaqLink = () => {
  const { t } = useTranslation();
  const store = useStore();
  const profession = store.profession.name.replace(/ /g, '-').toLowerCase();
  const currentLabel = store.label;
  const url = getFaqUrl(currentLabel, profession);
  if (!url) {
    return null;
  }
  return (
    <Button target="_blank" component="a" style={{ textTransform: 'capitalize', color: 'black', fontWeight: '400' }} href={url}>
      {t('footer.faq')}
    </Button>
  );
};

export default FaqLink;
