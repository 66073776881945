import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Store } from 'state/ContextStore';
/* MUI */
import { Box, Typography, Card, CardMedia, CardContent, Grid, SvgIcon, CardActions } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Quiz from 'components/icons/Quiz';

import CertificateTab from 'components/icons/CertificateTab';
import { getLabelNameFromDomain, GetTimeLabel } from 'helpers/Helpers';
import CourseButton from './CourseButton';

const SidebarCard = (props) => {
  const { lessons, image, title, accreditation, duration, courseBtn, extraPoints, extraPointsDescr, isAccredited } = props;
  const { t } = useTranslation();
  const currentTheme = getLabelNameFromDomain();
  const store = useContext(Store);
  const certTitle =
    currentTheme && currentTheme === 'po' ? t('introduction.sidebar.certificationPo') : t('introduction.sidebar.certification');
  const timeLabel = GetTimeLabel(duration);
  const selectedPackage = store?.baseProduct?.packages?.find((packag) => packag.nid === store.selectedPackageId);
  const selectedPackageTitle = selectedPackage?.tab_label?.toLowerCase() || '';
  const isArchive = parseFloat(duration) === 0 && selectedPackageTitle === 'archief'; // This needs a bit of flavour for PE

  const selectedProduct = store?.baseProduct?.title?.toLowerCase();
  const showAccreditation = isAccredited && (store?.label !== 'ewise' || selectedProduct?.includes('schoolleiders po'));

  return (
    <Card className="sticky">
      <Box className="course_introduction_image" position="relative">
        <CardMedia component="img" loading="lazy" height="194" image={image} alt={title} />
        {/* eslint-disable-next-line camelcase */}
      </Box>
      <CardContent className="course_details">
        <Grid container>
          {!extraPoints && duration !== '' && parseFloat(duration) !== 0 && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <AccessAlarmIcon color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="primaryBlue.main" fontSize="16px" lineHeight="15px">
                    {t('introduction.sidebar.duration')}
                  </Typography>
                  <Typography color="primaryBlue.main" fontSize="12px" lineHeight="15px">
                    {timeLabel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isArchive && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <SvgIcon component={CertificateTab} color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="primaryBlue.main" fontSize="16px" lineHeight="15px">
                    {certTitle}
                  </Typography>
                  <Typography color="primaryBlue.main" fontSize="12px" lineHeight="15px">
                    {extraPointsDescr || GetTimeLabel(duration, false)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {showAccreditation && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <SvgIcon component={CertificateTab} color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="primaryBlue.main" fontSize="16px" lineHeight="15px">
                    {certTitle}
                  </Typography>
                  <Typography color="primaryBlue.main" fontSize="12px" lineHeight="15px">
                    {extraPointsDescr || accreditation}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {lessons && lessons.length > 0 && (
            <Grid item xs={12}>
              <Grid container className="spec" alignItems="center">
                <Grid item className="icon">
                  <SvgIcon component={Quiz} color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="primaryBlue.main" fontSize="16px" lineHeight="15px">
                    {t('introduction.sidebar.quiz')}
                  </Typography>
                  <Typography variant="h1" color="primaryBlue.main" fontSize="12px" lineHeight="15px">
                    {lessons[0].minimum_score}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <CourseButton statusBtn={courseBtn} />
      </CardActions>
    </Card>
  );
};

SidebarCard.propTypes = {
  lessons: PropTypes.any,
  image: PropTypes.any,
  title: PropTypes.any,
  duration: PropTypes.any,
  extraPoints: PropTypes.any,
  extraPointsDescr: PropTypes.any,
  isAccredited: PropTypes.bool,
  accreditation: PropTypes.any,
  courseBtn: PropTypes.any
};

export default observer(SidebarCard);
